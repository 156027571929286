import api from '@/utils/api'
import {Message} from 'element-ui'

const defaultParams = {
  sort: 'name',
  order: 'asc',
  cache: true,
}

export const CAMPAIGN_TROUBLE_STATES = {
  TROUBLE: 10,
  MONITOR: 3,
  NO_ISSUES: 2,
  PRE_LAUNCH: 4,
  OTHER: 1,
  CANCELLED: 5,
}

export default {
  state: () => ({
    activeCampaigns: null,
    activeCampaignsSummary: null,
    archivedCampaigns: null,
    archivedCampaignsSummary: null,
    campaignCategories: [],
    campaignsWithTeams: [],
    activeCampaignCategory: null,
    allCampaigns: [],
    allCampaignsAreas: [],
    troubleStates: [
      {
        label: 'Troubled',
        key: CAMPAIGN_TROUBLE_STATES.TROUBLE,
      },
      {
        label: 'Monitor',
        key: CAMPAIGN_TROUBLE_STATES.MONITOR,
      },
      {
        label: 'No issues',
        key: CAMPAIGN_TROUBLE_STATES.NO_ISSUES,
      },
      {
        label: 'Pre-Launch',
        key: CAMPAIGN_TROUBLE_STATES.PRE_LAUNCH,
      },
      {
        label: 'Other',
        key: CAMPAIGN_TROUBLE_STATES.OTHER,
      },
      {
        label: 'Cancelled',
        key: CAMPAIGN_TROUBLE_STATES.CANCELLED,
      },
    ]
  }),
  getters: {
    campaignOrTeamById(state) {
      return (id) => {
        if (state.campaignsWithTeams) {
          return state.campaignsWithTeams.find(item => item.id === id)?.name
        }
      }
    },
    allNonArchivedCampaigns(state) {
      return state.allCampaigns.filter(campaign => !campaign.archived)
    }
  },
  mutations: {
    setActiveCampaigns(state, payload) {
      state.activeCampaigns = Object.freeze(payload)
    },

    setActiveCampaignsSummary(state, payload) {
      state.activeCampaignsSummary = Object.freeze(payload)
    },

    setArchivedCampaigns(state, payload) {
      state.archivedCampaigns = Object.freeze(payload)
    },

    setArchivedCampaignsSummary(state, payload) {
      state.archivedCampaignsSummary = Object.freeze(payload)
    },

    toggleArchived(state, payload) {
      let campaign = state.activeCampaigns.find(item => item.id === payload)
      if (campaign) {
        campaign.archived = campaign.archived === 1 ? 0 : 1
      }
      campaign = state.archivedCampaigns.find(item => item.id === payload)
      if (campaign) {
        campaign.archived = campaign.archived === 1 ? 0 : 1
      }
    },

    setCampaignCategories(state, payload) {
      state.campaignCategories = payload
    },

    setCampaignsWithTeams(state, payload) {
      state.campaignsWithTeams = payload
    },

    setActiveCampaignCategory(state, payload) {
      state.activeCampaignCategory = payload
    },

    setAllCampaigns(state, payload) {
      state.allCampaigns = payload
    },

    setAllCampaignsAreas(state, payload) {
      state.allCampaignsAreas = Object.freeze(payload)
    },
  },
  actions: {
    async fetchActiveCampaigns({state, commit}, params = defaultParams) {
      if (params.cache && state.activeCampaigns) return
      const result = await api.http.get('campaigns', {params})
      commit('setActiveCampaigns', result?.data)
      commit('setActiveCampaignsSummary', result?.data?.aggregates)
    },

    async fetchArchivedCampaigns({state, commit}, params = defaultParams) {
      if (params.cache && state.archivedCampaigns) return
      const result = await api.http.get('campaigns/archived', {params})
      commit('setArchivedCampaigns', result?.data)
      commit('setArchivedCampaignsSummary', result?.data?.aggregates)
    },

    async fetchAllCampaigns({commit}) {
      const result = await api.http.get('campaigns/all')
      commit('setAllCampaigns', result?.data)
    },

    async fetchCampaignsWithTeams({commit}) {
      const result = await api.http.get('campaigns/all', {
        params: {with_teams: 1},
      })
      commit('setCampaignsWithTeams', result?.data)
    },

    async editCampaign({dispatch}, formData) {
      await api.http.put(`campaign/${formData.id}`, formData)
      await dispatch('fetchActiveCampaigns', {...defaultParams, cache: false})
      await dispatch('fetchArchivedCampaigns', {...defaultParams, cache: false})
      Message.success('Campaign was changed successfully')
    },

    async fetchCampaignWorkingHours(_, id) {
      const result = await api.http.get(`campaign/${id}/working-hours`);

      return result?.data?.data;
    },

    async updateCampaignWorkingHours(_, {id, workingHours, timezone}) {
      await api.http.post(`campaign/${id}/working-hours`, {
        schedule: workingHours,
        timezone,
      })
      Message.success('Campaign working hours saved successfully')
    },

    async createCampaign({dispatch}, formData) {
      await api.http.post('campaign', formData)
      await dispatch('fetchActiveCampaigns', {...defaultParams, cache: false})
      await dispatch('fetchArchivedCampaigns', {...defaultParams, cache: false})
      Message.success('Campaign was created successfully')
    },

    async archiveCampaign({dispatch}, campaign) {
      await api.http.post(`campaign/${campaign.id}/archive`)
      await dispatch('fetchActiveCampaigns', {...defaultParams, cache: false})
      await dispatch('fetchArchivedCampaigns', {...defaultParams, cache: false})
    },

    async restoreCampaign({dispatch}, campaign) {
      await api.http.post(`campaign/${campaign.id}/unarchive`)
      await dispatch('fetchActiveCampaigns', {...defaultParams, cache: false})
      await dispatch('fetchArchivedCampaigns', {...defaultParams, cache: false})
    },

    async fetchCampaignArea(context, id) {
      const result = await api.http.get(`campaign/${id}/area`)
      return result?.data
    },

    async fetchAllCampaignsAreas({commit}, params) {
      const result = await api.http.get('campaign/area/all', {params})
      await commit('setAllCampaignsAreas', result?.data)
    },

    async fetchCampaignAreaObjects(context, points) {
      const result = await api.http.post('campaign/area/get-objects', points)
      return result?.data
    },

    async createCampaignArea(context, payload) {
      await api.http.post(`campaign/${payload.campaign_id}/area/save`, payload.points)
      Message.success('Campaign area was saved successfully')
    },

    async deleteCampaignArea(context, campaign_id) {
      await api.http.delete(`campaign/${campaign_id}/area`)
      Message.success('Campaign area was removed successfully')
    },

    async fetchCampaignCategories({commit}) {
      const result = await api.http.get('campaigncategory', {
        params: {nopag: 1},
      })
      await commit('setCampaignCategories', result?.data || [])
    },

    async fetchCampaignCategoryById(context, id) {
      const result = await api.http.get(`campaigncategory/${id}`)
      return result?.data
    },

    async deleteCampaignCategory(_, category_id) {
      await api.http.delete(`campaigncategory/${category_id}`)
      Message.success('Campaign Category was removed successfully')
    },

    async createCampaignCategory(_, form) {
      await api.http.post('campaigncategory', form)
      Message.success('Campaign Category was created successfully')
    },

    async updateCampaignCategory(_, form) {
      await api.http.put(`campaigncategory/${form.id}`, form)
      Message.success('Campaign Category was updated successfully')
    },

    async updateCampaignNote({dispatch}, form) {
      await api.http.post(`campaign/${form.id}/update-note`, form)
      dispatch('activity-log/fetchChangelog', null, {root: true})
      Message.success('Note changed successfully')
    },

    async updateCampaignTroubleState(_, {campaignId, troubleState}) {
      await api.http.post(`campaign/${campaignId}/update-trouble-state`, {trouble_state: troubleState})
      Message.success('State changed successfully')
    },

    async toggleIsPpc(_, id) {
      await api.http.post(`campaign/${id}/toggle-is-ppc`)
    },
  },
  namespaced: true,
}
