<template>
  <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="top"
  >
    <el-dialog
        :visible="visible"
        :before-close="handleClose"
        title="Edit Lead"
        width="94%"
    >
      <div class="row">
        <div class="col-md-6">
          <el-form-item label="Campaign" prop="campaign_id">
            <el-select
              v-model="form.campaign_id"
              filterable
            >
              <el-option
                v-for="campaign in allNonArchivedCampaigns"
                :key="campaign.id"
                :label="campaign.name"
                :value="campaign.id"
              />
            </el-select>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="ClientSite" prop="clientsite_id">
            <el-select
              v-model="form.clientsite_id"
              filterable
            >
              <el-option
                v-for="site in allClientSites"
                :key="site.id"
                :label="site.url"
                :value="site.id"
              />
            </el-select>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Lead Date" prop="lead_date">
            <el-date-picker
              v-model="form.lead_date"
              type="date"
              format="MM-dd-yyyy"
              value-format="yyyy-MM-dd"
              :clearable="false"
              placeholder="Select Date"
            />
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Lead Time" prop="lead_time">
            <el-time-picker
              v-model="form.lead_time"
              arrow-control
              format="HH:mm"
              placeholder="Select Time"
            />
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Lead Type" prop="lead_type">
            <el-radio-group v-model="form.lead_type">
              <el-radio label="Form">Form</el-radio>
              <el-radio label="Call">Call</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Lead Source" prop="lead_s">
            <el-radio-group v-model="form.lead_s">
              <el-radio label="Callrail">Callrail</el-radio>
              <el-radio label="Formidable">Formidable</el-radio>
            </el-radio-group>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Callrail Lead ID" prop="lead_id">
            <el-input v-model="form.lead_id"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Name" prop="lead_name">
            <el-input v-model="form.lead_name"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Email" prop="lead_email">
            <el-input v-model="form.lead_email"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Phone" prop="lead_phone">
            <el-input v-model="form.lead_phone"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Message" prop="message">
            <el-input v-model="form.message"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Source" prop="source">
            <el-input v-model="form.source"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Last URL" prop="last_url">
            <el-input v-model="form.last_url"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item label="Referrer" prop="referrer">
            <el-input v-model="form.referrer"/>
          </el-form-item>
        </div>

        <div class="col-md-6">
          <el-form-item>
            <el-switch
              v-model="form.notify"
              active-text="Notify"
            />
          </el-form-item>
        </div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancel</el-button>
        <el-button type="primary" @click="onSubmit">Save</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import {mapState, mapActions, mapGetters} from 'vuex'
import modalMixin from '@/mixins/modalMixin'

const defaultFormValues = {
  campaign_id: null,
  clientsite_id: null,
  lead_date: null,
  lead_time: null,
  lead_id: '',
  lead_s: null,
  lead_type: null,
  lead_name: '',
  notify: false,
}

export default {
  name: 'AddEditLeadDialog',
  mixins: [modalMixin],
  props: {
    visible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      form: { ...defaultFormValues },
      originFormValues: { ...defaultFormValues },
      rules: {
        campaign_id: [
          { required: true, message: 'Please select campaign', trigger: 'change' }
        ],
        clientsite_id: [
          { required: true, message: 'Please select client site', trigger: 'change' }
        ],
        lead_date: [
          { required: true, message: 'Please select lead date', trigger: 'change' }
        ],
        lead_time: [
          { required: true, message: 'Please select lead time', trigger: 'change' }
        ],
        lead_id: [
          { required: true, message: 'Please insert callrail lead id', trigger: 'change' }
        ],
        lead_type: [
          { required: true, message: 'Please select lead type', trigger: 'change' }
        ],
      },
    }
  },
  computed: {
    ...mapGetters('campaigns', [
      'allNonArchivedCampaigns',
    ]),
    ...mapState('sites', [
      'allClientSites'
    ]),
    isFormChanged() {
      return JSON.stringify(this.originFormValues) !== JSON.stringify(this.form)
    }
  },
  created() {
    this.fetchAllCampaigns()
    this.fetchAllClientSites()
  },
  methods: {
    ...mapActions('leads', [
      'createLead'
    ]),
    ...mapActions('campaigns', [
      'fetchAllCampaigns'
    ]),
    ...mapActions('sites', [
      'fetchAllClientSites'
    ]),
    onSubmit() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          await this.createLead(this.form)
          this.$emit('refetch-data')
          this.closeModal()
        }
      })
    },
    handleClose() {
      this.$_modalMixin_closeConfirmation(this.closeModal, this.isFormChanged)
    },
    closeModal() {
      this.resetForm()
      this.$emit('close')
    },
    resetForm() {
      this.form = { ...defaultFormValues }
      this.$refs.form.resetFields()
    }
  }
}
</script>

<style lang="scss" scoped>

::v-deep .el-dialog {
  max-width: 700px;
}

::v-deep .el-dialog__body {
  margin-bottom: -20px;
}
</style>
